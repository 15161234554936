import { faker } from "@faker-js/faker";
import Party from "./components/Party";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "../styles.css";

import { createRoot } from "react-dom/client";

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const room = queryParams.get("room");
  const username = name || faker.internet.userName();
  const roomId = room || "tackle-party";

  return (
    <main>
      <Party username={username} roomId={roomId} />
    </main>
  );
}

createRoot(document.getElementById("app")!).render(
  <Router>
    <App />
  </Router>
);
